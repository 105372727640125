import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import db from "../../utils/Utility";
import Loader from "../../Loader";
import SimpleTable from '../generalComponets/SimpleTable';

const AccountsInfo = ({ expanded }) => {
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [Products, setProducts] = useState([]);
  const [loader, setLoader] = useState(false)
  const [filtereData, setfiltereData] = useState([]);

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [mobile, setMobile] = useState('');
  const [personAdd, setPersonAdd] = useState('');
  const [dues, setDues] = useState('');
  const [id, setId] = useState('');
  const [sumTotalBills, setSumTotalBills] = useState(0);
  const [payments, setPayments] = useState(0);
  const [generalInfo, setGeneralInfo] = useState();

  
  useEffect(() => {
    async function fetchData() {
      try {
        const allData = await db.getAll('Clients');
        setData(allData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (expanded === 'panel5') {
      // console.log('its running ')
      fetchData();
    }
  }, [ expanded ]);


  const fetchDataById = async (getId, getDues) => {
    setLoader(true);
    try {
  
      const allData = await db.getAll('RozNamchaBook');
      console.log(allData)
      let summingAllPayments = getDues  == '' ? 0: parseInt(getDues) ;
      // Filter and process data sequentially
      // const getFilter = [];
      // for (const bill of allData) {// fetching all bill generated for this this getId user
      //   if (parseInt(bill.clientId) === parseInt(getId)) {
      //     const getRelatedData = await db.getAll('BillBook');
      //     const getItems = getRelatedData.filter(prod => prod.billBookHeadId === bill.id);
      //     bill.history=getItems
      //     getFilter.push({ bill});
      //     summingAllPayments = parseInt(summingAllPayments) + parseInt(bill.totalCurr)
      //   }
      // }

      
      // fetchallDataing all payments done by this getId user
      const getRelatedPayments =  allData//await db.getAll('Clients');
      const getPayments = getRelatedPayments.filter(prod => prod.clientAcc === getId);
      setPayments(getPayments)

      setSumTotalBills(summingAllPayments)
      // setfiltereData(getFilter)
      setLoader(false);
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoader(false); // Ensure loader is turned off in case of error
    }
  };
  
  const handleChange = async (event) => {
    setSelectedValue(event.target.value);
    const getEachData = data.filter(item => item.id === event.target.value);

    setName(getEachData[0].personName)
    setDate(getEachData[0].Date)
    setPersonAdd(getEachData[0].personAdd)
    setMobile(getEachData[0].mobileNo)
    setDues(getEachData[0].personDues)
    setId(getEachData[0].id)
    setGeneralInfo(getEachData)
    fetchDataById(event.target.value, getEachData[0].personDues)
  }
  
  return (
    <>
    <Box display="flex" justifyContent="center" alignItems="center" >
        <Loader show={loader} />
      <div>
        <Select 
         value={selectedValue}
         onChange={handleChange}
         defaultValue={data && data[0] ? data[0].personName : 'Select Name' }>
        {data.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.personName}
          </MenuItem>
        ))}
      </Select>
        {/* <br />
        <Card sx={{ minWidth: 275, maxWidth: '80%', margin: '0 auto' }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {date}
            </Typography>
            <Typography variant="h5" component="div">
              {name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {mobile}
            </Typography>
            
            <Typography variant="h2" component="div"> 
              {  parseInt(sumTotalBills) }
            </Typography>
            <Typography variant="body2 " color="text.secondary">
              <br />
              {dues}
            </Typography>
          </CardContent>
        </Card> */}
        <br/>

        
      </div>
    </Box>


    <Box display="flex" sx={{ minWidth: 275, maxWidth: '80%', margin: '0 auto' }} justifyContent="center" alignItems="center" >
      
        <div className='simpleTable'>
          {payments.length > -1 ?
          <SimpleTable getList = {payments} generalInfo={generalInfo} />
          : null }
        </div>
    </Box>
    <br/>
    {/* <Box display="flex" justifyContent="center" alignItems="center" >
      <div className='table'>
          {filtereData.length > 0 ?
          <CollapsibleTable getList={filtereData} />
          : null }
        </div> 
    </Box> */}
    </>
  );
};

export default AccountsInfo;
