import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import constants  from "../../Constants";
import db from "../../utils/Utility";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Accounts() { 
    const [submitBtn, setSubmitBtn] = useState(false)
    
    const [accountValue, setAccountValue] = useState({
        personName: '',
        mobileNo: '',
        personAdd: '',
        personRef: '',
        personDues: ''
      });     
    const handleChange = (event) => {
        const d = Date.now();
        const getDate = formatDate(d);
        const { name, value } = event.target;
        setAccountValue((prevValues) => ({
            ...prevValues,
            [name]: value, Date:getDate
          }));
          console.log(accountValue)
        //   if( accountValue.personName != undefined || accountValue.mobileNo != undefined || accountValue.personName != '' || accountValue.mobileNo != '' ) {
        //     setSubmitBtn(true)
        //     console.log(accountValue.personName != undefined)
        //     console.log(accountValue.mobileNo != undefined)
        //     console.log(accountValue.personName != '')
        //     console.log(accountValue.mobileNo != '')

        //     console.log('no')
        //   }
        //   else{setSubmitBtn(false)}
    } 

    const handleSubmit = (e) => { 
        console.log(accountValue)
        e.preventDefault();
    }
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);      
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so we add 1
        const year = date.getFullYear();      
        return `${day}/${month}/${year}`;
      };
    const saveToDatabase = async () => {
        // const CreateAccount = { 
        //   Name: accountValue.personName,
        //   PersonMobile: accountValue.mobileNo,
        //   PersonAdd: accountValue.personAdd,
        //   PersonRef: accountValue.personRef,
        //   PersonDues: accountValue.personDues,
        // };
        let getValues = accountValue
        try {
          await db.set('Clients', getValues);
        //   await db.clear('Clients')  
          const updatedData = await db.getAll('Clients');

          toast.success(constants.toastSuccessMsg, {
            position: 'top-center',
            autoClose: 3000, // Close the toast after 3 seconds (optional)
             });
          getValues = ''   
          setAccountValue(  { 
            personName: '',
            mobileNo: '',
            personAdd: '',
            personRef: '',
            personDues: ''
          });
          console.log(updatedData)

        } catch (error) {
            toast.error(constants.toastErrorMsg, {
                position: 'top-center',
                autoClose: 3000, // Close the toast after 3 seconds (optional)
              });
          console.error('Error saving to database:', error);
        }
      };
    return (
        <form className='AccountsCmp' onSubmit={handleSubmit}>
            <TextField
                id="personName"
                name="personName"
                label={constants.accounts.personName}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={accountValue.personName}
                required
            />
            <TextField
                id="mobileNo"
                name="mobileNo"
                label={constants.accounts.mobileNo}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={accountValue.mobileNo}
                required
            />
            <TextField
                id="personAdd"
                name="personAdd"
                label={constants.accounts.personAdd}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={accountValue.personAdd}
            />
            <TextField
                id="personRef"
                name="personRef"
                label={constants.accounts.personRef}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={accountValue.personRef}
            />
            {/* <TextField
                id="personDues"
                name="personDues"
                label={constants.accounts.personDues}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={accountValue.personDues}
            /> */}

            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={submitBtn}
                size="large" onClick={() => saveToDatabase()}
                sx={{ marginTop: 2 }}
            >
                {constants.saveBtn}
            </Button>
        </form>
    )
}
