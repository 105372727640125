import React, { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Incoming from '../incoming/Incoming';
import constants from '../../Constants';
import Accounts from '../accounts/Accounts';
import AccountsInfo from '../accounts/AccountsInfo';

export default function Dashboard() {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(1);
  const [buying, setBuying] = useState(false);
  const [rozNamcha, setRozNamcha] = useState(false);
  const [selling, setSelling] = useState(true);
 
  const handleTabChange = (event, newValue) => {
    console.log(newValue)
    setBuying(false)
    setRozNamcha(false)
    setSelling(false)
    setValue(newValue);
    // if(newValue == 2){
    //   setBuying(true)
    // }
    // else 
    if(newValue == 0){
      setRozNamcha(true)
    }
    else if(newValue == 1){
      setSelling(true)
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
       <Tabs value={value} onChange={handleTabChange} className='printHideTabs' centered aria-label="icon label tabs example">
        {/* <Tab icon={<PhoneIcon />} label={constants.dashboard.buy} /> */}
        <Tab icon={<SensorOccupiedIcon />} label={constants.dashboard.roznamcha} />
        <Tab icon={<PersonPinIcon />} label={constants.dashboard.sell} />
      </Tabs>
        {/* <div>
            <Incoming expanded={expanded} />     
        </div> */}
      {
         
      rozNamcha == true? 
      <div>
            <Incoming expanded={expanded} />
      </div>
      : selling == true?
      
      <div>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}> {constants.dashboard.accountsInfo}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <AccountsInfo expanded={expanded} /> {/* Pass expanded state here */}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} className='printHide' onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}> {constants.dashboard.accountsCreate}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Accounts />
          </Typography>
        </AccordionDetails>
      </Accordion> 
      
          
      </div>
      :
      null
      }
    </div>
  );
}