import React, { useState, useEffect } from 'react';
import { Button, TextField, List, ListItem, ListItemText, Container, Typography } from '@mui/material';
import db from "../../utils/Utility";
import {  Select, MenuItem, FormControl, InputLabel } from '@mui/material'; // Ensure these are correctly imported
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Logo  from '../logo/Logo';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Recover() {
    
  const [data, setData] = useState('')
  const [error, setError] = useState('')
  const [typePass, setTypePass] = useState('')
  const [mobile, setMobile] = useState('')
  const [otp, setOtp] = useState('')
  const [userProfile, setUserProfile] = useState([])
  const url = `https://rozacc.wasily.net/xaosoftPNG.png`
  const navigate = useNavigate();

  const handleAddCurrency = async (event) => {
    event.preventDefault();

  };
  const handleRecover = () => {
    try {
        const payload = {
            mobile: mobile, 
            password: typePass,
            otp: otp
        };
        console.log(payload)
        axios.post('https://roznamchaback.wasily.net/recover', payload)
      .then(async response => {
        
        if(response.status === 200) {
            const fetchCurrencies = JSON.parse(response.data.currencies)
            const roznamcha = JSON.parse(response.data.data)
            const clients = JSON.parse(response.data.clients)
            const profile = response.data.profile
            console.log(fetchCurrencies, profile)
            try {
                await db.clear('Currency'); //clearing the currency table
                await db.clear('Profile'); //clearing the currency table
                await db.clear('RozNamchaBook'); //clearing the currency table
                await db.clear('Clients'); //clearing the currency table
                await db.set('Profile', profile);
            } catch (error) {
                alert('Error in Profile please contact Wasily Technology')
            }
            console.log(response.data.currencies)
            for (const currency of fetchCurrencies) { // pushing currencies in the Curency table
                try {
                    const getCurrency = {currency:currency['currency'], id: currency['id']}
                    // Await the async operation to save each currency
                    await db.set('Currency', getCurrency);
                    console.log(`Currency ${currency} saved successfully.`);
                } catch (error) {
                    console.error(`Error saving currency ${currency}:`, error);
                    // Optionally handle errors, e.g., continue with the next item
                }
            }

            for (const roz of roznamcha) { // pushing currencies in the Curency table
              try {
                  const getRoz = {
                      account:roz['account'],
                      clientAcc: roz['clientAcc'],
                      id: roz['id'],
                      date: roz['date'],
                      description: roz['description'],
                      payIn: roz['payIn'],
                      payOut: roz['payOut'],
                  }
                  // Await the async operation to save each currency
                  await db.set('RozNamchaBook', getRoz);
                  console.log(`RozNamchaBook ${roz} saved successfully.`);
              } catch (error) {
                  console.error(`Error saving roznamcha ${roz}:`, error);
                  // Optionally handle errors, e.g., continue with the next item
              }
          }
          
          for (const cl of clients) { // pushing currencies in the Curency table
            try {
                const client = {
                    Date:cl['Date'],
                    mobileNo: cl['mobileNo'],
                    id: cl['id'],
                    personAdd: cl['personAdd'],
                    personName: cl['personName'],
                    personRef: cl['personRef'],
                }
                // Await the async operation to save each currency
                await db.set('Clients', client);
                console.log(`Clients ${cl} saved successfully.`);
            } catch (error) {
                console.error(`Error saving clnamcha ${cl}:`, error);
                // Optionally handle errors, e.g., continue with the next item
            }
        }
        }
        navigate('/dashboard')
       
      })
      .catch(err => {
        console.log(err)
        if(err.response.status === 404){
            setError(err.response.data.message)
        }
      }); 
    } catch (error) {
        console.log(error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Login form submitted');
  };
    const getMobile = (e) =>{
        setMobile(e.target.value)
        return e.target.value
    } 
    const fetchPassword = (e) =>{
    setTypePass(e.target.value)
    return e.target.value
    }
    const getOtp = (e) =>{
    setOtp(e.target.value)
    return e.target.value
    }
  return (
    <Card sx={{ maxWidth: 400, margin: 'auto',  }}>
        
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <Logo
        imageUrl={url}
        altText="Wasily Technology" />
        </Typography>
        <Typography variant="h4" gutterBottom>
        Recover
      </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            id="username"
            label="Mobile"
            variant="outlined"
            fullWidth
            margin="normal"
            value={mobile}
            required
            onChange={getMobile}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={typePass}
            margin="normal"
            onChange={fetchPassword}
            required
          />
          <TextField
            id="otp"
            label="OTP"
            variant="outlined"
            fullWidth
            value={otp}
            margin="normal"
            onChange={getOtp}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large" onClick={()=>handleRecover()}
            sx={{ marginTop: 2 }}
          >
            Recover
          </Button>
          <label className='errorMsg'>{error}</label>
        </form>
      </CardContent>
    </Card>
  )
}

export default Recover
