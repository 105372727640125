// Login.js

import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Logo  from '../logo/Logo';
import constants  from '../../Constants';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import db from "../../utils/Utility";
import { type } from '@testing-library/user-event/dist/type';

const Login = () => {
  const [data, setData] = useState('')
  const [error, setError] = useState('')
  const [typePass, setTypePass] = useState('')
  const [userProfile, setUserProfile] = useState([])
    
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Login form submitted');
  };
  const url = `https://rozacc.wasily.net/xaosoftPNG.png`
  const navigate = useNavigate();

  const WebSrn = 'khi.xaosoft.com';
  const LoginApp = async () =>{
    //   const getProfile = await db.getAll('Profile');
    const getPass = userProfile[0]?.password
    if(userProfile[0]?.password === typePass){
        localStorage.setItem('Logged', true)
        navigate('/dashboard')
        setError('')
    }
    else{
        setError(constants.errorMsg)
    }
    // axios.post('https://smartschool.host/WebSr', {WebSrn})
    //   .then(response => {
    //     setData(response.data);
    //   })
    //   .catch(err => {
    //     setError(err.message);
    //   });
    // // axiosInstance.post('https://smartschool.host/WebSr', {WebSrn}).then(response => {
    // //   console.log(response.data)
    // // }).catch( error => {
    // //   console.log('app error', error);
    // // })
    // navigate('/currencyaccount');
  }
  async function fetchData() {
    const getProfile =await db.getAll('Profile');
    setUserProfile(getProfile)
    console.log(userProfile[0])
    }

  useEffect(  () => {
    fetchData()
},[])

    const fetchPassword = (e) =>{
        setTypePass(e.target.value)
        return e.target.value
    }
  return (
    <Card sx={{ maxWidth: 400, margin: 'auto',  }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <Logo
        imageUrl={url}
        altText="Wasily Technology" />
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            id="username"
            label=""
            variant="outlined"
            fullWidth
            margin="normal"
            value={userProfile[0]?.mobile}
            required
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={typePass}
            margin="normal"
            onChange={fetchPassword}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large" onClick={()=>LoginApp()}
            sx={{ marginTop: 2 }}
          >
            Login
          </Button>
          <label className='errorMsg'>{error}</label>
        </form>
      </CardContent>
    </Card>
  );
};

export default Login;
