// src/utils/indexedDB.js

import { openDB } from 'idb';

const dbPromise = openDB('roznamchaAcc', 1, {
  async upgrade(db) {

    // Create object stores
    db.createObjectStore('RozNamchaBook', { keyPath: 'id', autoIncrement: true });
    db.createObjectStore('Profile', { keyPath: 'id', autoIncrement: true  }); 
    const clientsStore = db.createObjectStore('Clients', { keyPath: 'id', autoIncrement: true });
    const currencyStore = db.createObjectStore('Currency', { keyPath: 'id', autoIncrement: true });

    // Check if Currency store is empty
    const currencyCount = await currencyStore.count();
    console.log(currencyCount===0)
    if (currencyCount === 0) {
      // Insert a default record if empty
      await currencyStore.add({ id: 1, currency: 'افغانی' }); // Example default record
      // await clientsStore.add({ id: 0, personName: 'دوکان', }); // Example client record
      await clientsStore.add({ id: 1, personName: 'شخصي', }); // Example client record
    }
  },
});

const db = {
  async get(storeName, id) {
    return (await dbPromise).get(storeName, id);
  },
  async set(storeName, value) {
    console.log(storeName, value)
    return (await dbPromise).put(storeName, value);
  },
  async getAll(storeName) {
    return (await dbPromise).getAll(storeName);
  },
  async delete(storeName, id) {
    return (await dbPromise).delete(storeName, id);
  },
  async clear(storeName) {
    return (await dbPromise).clear(storeName);
  },
};

export default db;
